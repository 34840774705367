import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Rating,
  Box,
  Chip,
} from '@mui/material';
import { motion } from 'framer-motion';

const testimonials = [
  {
    name: "Marie Dubois",
    company: "La Petite Boulangerie",
    role: "Propriétaire",
    rating: 5,
    comment: "Grâce à EntrePixel, notre boulangerie a vu sa clientèle augmenter de 30% en seulement 3 mois. La visibilité en ligne est essentielle aujourd'hui, et leur plateforme nous a permis de nous démarquer.",
    sector: "Restauration",
    plan: "Premium"
  },
  {
    name: "Thomas Martin",
    company: "Tech Solutions",
    role: "Directeur Marketing",
    rating: 5,
    comment: "L'interface est intuitive et les statistiques nous permettent de mieux comprendre notre audience. Le support client est réactif et professionnel. Un excellent investissement pour notre entreprise.",
    sector: "Technologies",
    plan: "Elite"
  },
  {
    name: "Sophie Laurent",
    company: "Bien-Être & Spa",
    role: "Gérante",
    rating: 4,
    comment: "La mise en place a été simple et rapide. Nous avons pu personnaliser notre profil facilement et les retours de nos clients sont très positifs. Je recommande vivement !",
    sector: "Santé",
    plan: "Basic"
  },
  {
    name: "Pierre Durand",
    company: "Durand Construction",
    role: "PDG",
    rating: 5,
    comment: "Les fonctionnalités de mise en avant de nos réalisations sont parfaites. Nous avons obtenu plusieurs contrats importants grâce à notre présence sur EntrePixel.",
    sector: "Construction",
    plan: "Elite"
  },
  {
    name: "Isabelle Moreau",
    company: "Style & Mode",
    role: "Directrice",
    rating: 5,
    comment: "Un excellent rapport qualité-prix. La plateforme nous a permis de toucher une clientèle plus jeune et plus connectée. Les statistiques sont très utiles pour ajuster notre stratégie.",
    sector: "Commerce",
    plan: "Premium"
  },
  {
    name: "Lucas Bernard",
    company: "EcoTransport",
    role: "Responsable Commercial",
    rating: 4,
    comment: "La possibilité de mettre en avant nos engagements écologiques a été déterminante. L'équipe d'EntrePixel comprend vraiment les besoins des entreprises modernes.",
    sector: "Transport",
    plan: "Premium"
  }
];

const MotionCard = motion(Card);

const Testimonials = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Ils nous font confiance
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center" sx={{ mb: 6 }}>
          Découvrez les expériences de nos clients
        </Typography>

        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={6} key={index}>
              <MotionCard
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    transition: 'transform 0.3s ease-in-out',
                  },
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar
                      sx={{
                        width: 60,
                        height: 60,
                        bgcolor: 'primary.main',
                        mr: 2,
                      }}
                    >
                      {testimonial.name.charAt(0)}
                    </Avatar>
                    <Box>
                      <Typography variant="h6" component="h2">
                        {testimonial.name}
                      </Typography>
                      <Typography color="textSecondary" gutterBottom>
                        {testimonial.role} chez {testimonial.company}
                      </Typography>
                    </Box>
                  </Box>

                  <Rating value={testimonial.rating} readOnly sx={{ mb: 2 }} />

                  <Typography variant="body1" paragraph sx={{ mb: 3 }}>
                    "{testimonial.comment}"
                  </Typography>

                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    <Chip
                      label={testimonial.sector}
                      color="primary"
                      variant="outlined"
                    />
                    <Chip
                      label={`Forfait ${testimonial.plan}`}
                      color="secondary"
                      variant="outlined"
                    />
                  </Box>
                </CardContent>
              </MotionCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Testimonials;
