import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import {
  Timeline,
  Person,
  Business,
  Assessment,
} from '@mui/icons-material';

const Dashboard = () => {
  const companyStats = {
    views: 1234,
    contacts: 56,
    rating: 4.5,
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Tableau de bord
      </Typography>

      <Grid container spacing={4}>
        {/* Stats Cards */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Vues du profil
              </Typography>
              <Typography variant="h4">{companyStats.views}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Contacts reçus
              </Typography>
              <Typography variant="h4">{companyStats.contacts}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Note moyenne
              </Typography>
              <Typography variant="h4">{companyStats.rating}/5</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Company Profile */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6">Profil de l'entreprise</Typography>
              <Button variant="contained" color="primary">
                Modifier
              </Button>
            </Box>
            <List>
              <ListItem>
                <ListItemText 
                  primary="Nom de l'entreprise"
                  secondary="EntrePixel Example"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Secteur d'activité"
                  secondary="Technologies"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Description"
                  secondary="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Subscription Info */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Abonnement
            </Typography>
            <List>
              <ListItem>
                <ListItemText 
                  primary="Forfait actuel"
                  secondary="Premium"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Date de renouvellement"
                  secondary="15/01/2024"
                />
              </ListItem>
            </List>
            <Button variant="outlined" color="primary" fullWidth>
              Gérer l'abonnement
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
