import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Rating,
  Chip,
  Grid,
  Skeleton,
  Avatar,
  Divider,
} from '@mui/material';
import {
  Business as BusinessIcon,
  LocationOn as LocationIcon,
  People as PeopleIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';

interface Company {
  id: string;
  name: string;
  description: string;
  sector: string;
  region: string;
  rating: number;
  employeeCount: number;
  logo?: string;
  tags: string[];
}

interface SearchResultsProps {
  results: Company[];
  loading?: boolean;
  error?: string;
}

const CompanySkeleton = () => (
  <Card sx={{ mb: 2 }}>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item>
          <Skeleton variant="circular" width={64} height={64} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="text" width="60%" height={32} />
          <Skeleton variant="text" width="40%" height={24} />
          <Skeleton variant="text" width="80%" height={24} />
          <Box sx={{ mt: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={60} />
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const CompanyCard = ({ company }: { company: Company }) => (
  <Card
    component={motion.div}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    whileHover={{ scale: 1.02 }}
    sx={{
      mb: 2,
      transition: 'transform 0.2s',
      '&:hover': {
        boxShadow: (theme) => theme.shadows[4],
      },
    }}
  >
    <CardContent>
      <Grid container spacing={2}>
        <Grid item>
          <Avatar
            src={company.logo}
            sx={{ width: 64, height: 64 }}
          >
            <BusinessIcon />
          </Avatar>
        </Grid>
        <Grid item xs>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Typography variant="h6" gutterBottom>
              {company.name}
            </Typography>
            <Rating value={company.rating} readOnly precision={0.5} />
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
              <BusinessIcon sx={{ fontSize: 20, mr: 0.5 }} />
              <Typography variant="body2">{company.sector}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
              <LocationIcon sx={{ fontSize: 20, mr: 0.5 }} />
              <Typography variant="body2">{company.region}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
              <PeopleIcon sx={{ fontSize: 20, mr: 0.5 }} />
              <Typography variant="body2">{company.employeeCount} employés</Typography>
            </Box>
          </Box>

          <Typography variant="body2" color="text.secondary" paragraph>
            {company.description}
          </Typography>

          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {company.tags.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                size="small"
                variant="outlined"
                sx={{ borderRadius: 1 }}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const SearchResults: React.FC<SearchResultsProps> = ({ results, loading, error }) => {
  if (error) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box>
        {[1, 2, 3].map((i) => (
          <CompanySkeleton key={i} />
        ))}
      </Box>
    );
  }

  if (results.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="h6" color="text.secondary">
          Aucun résultat trouvé
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">
          {results.length} résultat{results.length > 1 ? 's' : ''} trouvé{results.length > 1 ? 's' : ''}
        </Typography>
        <Divider sx={{ my: 2 }} />
      </Box>
      {results.map((company) => (
        <CompanyCard key={company.id} company={company} />
      ))}
    </Box>
  );
};

export default SearchResults;
