import React from 'react';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { motion } from 'framer-motion';

const MotionAccordion = motion(Accordion);

const faqItems = [
  {
    question: "Comment fonctionne EntrePixel ?",
    answer: "EntrePixel est une plateforme qui permet aux entreprises de créer leur profil professionnel en ligne, d'augmenter leur visibilité et de se connecter avec des clients potentiels. Vous pouvez créer votre profil, choisir votre forfait et commencer à développer votre présence en ligne en quelques minutes."
  },
  {
    question: "Quels sont les différents forfaits disponibles ?",
    answer: "Nous proposons trois forfaits adaptés à vos besoins : Basic (29.99€/mois), Premium (24.99€/mois) et Elite (19.99€/mois). Chaque forfait offre des fonctionnalités différentes, du simple profil de base à l'accès complet à toutes nos fonctionnalités avancées."
  },
  {
    question: "Comment puis-je modifier mon forfait ?",
    answer: "Vous pouvez modifier votre forfait à tout moment depuis votre tableau de bord dans la section 'Abonnement'. Les changements prendront effet à la prochaine période de facturation."
  },
  {
    question: "Quels moyens de paiement acceptez-vous ?",
    answer: "Nous acceptons les principales cartes de crédit (Visa, Mastercard, American Express) ainsi que les paiements par virement bancaire pour les abonnements annuels."
  },
  {
    question: "Comment puis-je optimiser mon profil ?",
    answer: "Pour optimiser votre profil, assurez-vous de remplir toutes les informations demandées, d'ajouter des photos de qualité, de décrire précisément vos services et de maintenir vos informations à jour. Notre équipe de support peut vous guider dans cette démarche."
  },
  {
    question: "Quelle est votre politique de confidentialité ?",
    answer: "Nous prenons la protection de vos données très au sérieux. Toutes les informations que vous nous confiez sont cryptées et stockées de manière sécurisée. Nous ne partageons jamais vos données avec des tiers sans votre consentement explicite."
  },
  {
    question: "Comment puis-je contacter le support ?",
    answer: "Notre équipe de support est disponible par email 24/7 pour les clients Elite, en horaires étendus pour les clients Premium, et pendant les heures de bureau pour les clients Basic. Vous pouvez également utiliser le formulaire de contact dans votre tableau de bord."
  },
  {
    question: "Puis-je annuler mon abonnement ?",
    answer: "Oui, vous pouvez annuler votre abonnement à tout moment depuis votre tableau de bord. L'annulation prendra effet à la fin de votre période de facturation en cours."
  },
  {
    question: "Comment sont gérées les statistiques de mon profil ?",
    answer: "Nous fournissons des statistiques détaillées sur les visites de votre profil, les contacts reçus et l'engagement des utilisateurs. Les données sont mises à jour en temps réel et accessibles depuis votre tableau de bord."
  },
  {
    question: "Y a-t-il un engagement minimum ?",
    answer: "Non, il n'y a pas d'engagement minimum. Vous pouvez choisir la durée qui vous convient : 3 mois pour le forfait Basic, 6 mois pour le Premium, ou 1 an pour l'Elite, avec des tarifs préférentiels pour les engagements plus longs."
  }
];

const FAQ = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Box component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Foire Aux Questions
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center" sx={{ mb: 6 }}>
          Trouvez rapidement des réponses à vos questions
        </Typography>

        <Paper elevation={3} sx={{ p: 3 }}>
          {faqItems.map((item, index) => (
            <MotionAccordion
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              sx={{ 
                mb: 1,
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <Typography variant="h6" component="h2">
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="textSecondary">
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </MotionAccordion>
          ))}
        </Paper>
      </Box>
    </Container>
  );
};

export default FAQ;
