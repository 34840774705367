import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Search as SearchIcon,
  Business as BusinessIcon,
  Handshake as HandshakeIcon,
  TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const features = [
    {
      icon: <SearchIcon fontSize="large" color="primary" />,
      title: 'Recherche avancée',
      description: 'Trouvez rapidement les entreprises qui correspondent à vos critères grâce à notre moteur de recherche intelligent.',
    },
    {
      icon: <BusinessIcon fontSize="large" color="primary" />,
      title: 'Profil détaillé',
      description: 'Créez un profil professionnel complet pour mettre en valeur votre entreprise et vos services.',
    },
    {
      icon: <HandshakeIcon fontSize="large" color="primary" />,
      title: 'Mise en relation',
      description: "Connectez-vous facilement avec d'autres entreprises et développez votre réseau professionnel.",
    },
    {
      icon: <TrendingUpIcon fontSize="large" color="primary" />,
      title: 'Opportunités',
      description: 'Découvrez de nouvelles opportunités commerciales et développez votre activité.',
    },
  ];

  const plans = [
    {
      title: 'Basic',
      price: '29.99€',
      duration: '3 mois',
      features: [
        'Profil entreprise basique',
        '10 recherches par mois',
        'Support email',
      ],
      buttonText: 'Commencer',
      buttonVariant: 'outlined',
    },
    {
      title: 'Premium',
      price: '49.99€',
      duration: '6 mois',
      features: [
        'Profil entreprise avancé',
        'Recherches illimitées',
        'Support prioritaire',
        'Statistiques détaillées',
      ],
      buttonText: 'Choisir Premium',
      buttonVariant: 'contained',
      highlighted: true,
    },
    {
      title: 'Elite',
      price: '89.99€',
      duration: '1 an',
      features: [
        'Profil entreprise premium',
        'Recherches illimitées',
        'Support dédié 24/7',
        'Statistiques avancées',
        'Fonctionnalités exclusives',
      ],
      buttonText: 'Choisir Elite',
      buttonVariant: 'outlined',
    },
  ];

  const handlePlanSelection = (planTitle: string) => {
    if (!isAuthenticated) {
      // Si l'utilisateur n'est pas connecté, on le redirige vers la page de connexion
      // avec les informations du forfait sélectionné
      navigate('/login', { 
        state: { 
          selectedPlan: planTitle.toLowerCase(),
          redirectAfterLogin: '/register',
          message: "Veuillez vous connecter ou vous inscrire pour choisir un forfait"
        } 
      });
    } else {
      // Si l'utilisateur est connecté, on le redirige directement vers la page de paiement
      navigate('/register', { state: { selectedPlan: planTitle.toLowerCase() } });
    }
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                component="h1"
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  mb: 4,
                  fontSize: { xs: '2.5rem', md: '3.75rem' },
                }}
              >
                Connectez votre entreprise à de nouvelles opportunités
              </Typography>
              <Typography variant="h5" paragraph>
                Rejoignez la première plateforme de mise en relation entre entreprises innovantes.
              </Typography>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{ mt: 4 }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => navigate('/register')}
                >
                  S'inscrire gratuitement
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ color: 'white', borderColor: 'white' }}
                  onClick={() => navigate('/login')}
                >
                  Se connecter
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/images/hero-illustration.svg"
                alt="Illustration"
                sx={{
                  width: '100%',
                  maxWidth: 500,
                  display: { xs: 'none', md: 'block' },
                  mx: 'auto',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{ mb: 6 }}
        >
          Pourquoi choisir EntrePixel ?
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  p: 2,
                }}
              >
                <Box sx={{ p: 2 }}>{feature.icon}</Box>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Pricing Section */}
      <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ mb: 6 }}
          >
            Nos forfaits
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {plans.map((plan, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
              >
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    ...(plan.highlighted && {
                      border: 2,
                      borderColor: 'primary.main',
                      transform: 'scale(1.05)',
                    }),
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      align="center"
                    >
                      {plan.title}
                    </Typography>
                    <Typography
                      variant="h4"
                      align="center"
                      color="primary"
                      sx={{ mb: 2 }}
                    >
                      {plan.price}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      sx={{ mb: 3 }}
                    >
                      {plan.duration}
                    </Typography>
                    <Box component="ul" sx={{ p: 0 }}>
                      {plan.features.map((feature, index) => (
                        <Typography
                          component="li"
                          variant="body1"
                          sx={{ listStyle: 'none', mb: 1 }}
                          key={index}
                        >
                          ✓ {feature}
                        </Typography>
                      ))}
                    </Box>
                  </CardContent>
                  <CardActions sx={{ p: 2, pt: 0 }}>
                    <Button
                      fullWidth
                      variant={plan.buttonVariant as 'outlined' | 'contained'}
                      color="primary"
                      size="large"
                      onClick={() => handlePlanSelection(plan.title)}
                    >
                      {plan.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
