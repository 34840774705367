import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export interface RegisterData {
  companyName: string;
  address: string;
  sector: string[];
  description: string;
  contactName: string;
  email: string;
  phone: string;
  website?: string;
  socialMedia?: {
    facebook?: string;
    linkedin?: string;
    twitter?: string;
  };
  plan: string;
  duration: string;
}

export const register = async (data: RegisterData) => {
  try {
    const response = await axios.post(`${API_URL}/api/register`, data);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.message || 'Erreur lors de l\'inscription');
    }
    throw new Error('Erreur de connexion au serveur');
  }
};
