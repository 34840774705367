import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Terms = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Link component={RouterLink} to="/" color="inherit">
          Accueil
        </Link>
        <Typography color="text.primary">Conditions d'utilisation</Typography>
      </Breadcrumbs>

      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Conditions d'utilisation
        </Typography>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            1. Acceptation des conditions
          </Typography>
          <Typography paragraph>
            En accédant et en utilisant la plateforme EntrePixel, vous acceptez d&apos;être lié par les présentes conditions d&apos;utilisation. Si vous n&apos;acceptez pas ces conditions, veuillez ne pas utiliser notre service.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            2. Description du service
          </Typography>
          <Typography paragraph>
            EntrePixel est une plateforme de mise en relation entre entreprises qui permet aux utilisateurs de créer des profils professionnels, de rechercher des partenaires commerciaux et d&apos;interagir avec d&apos;autres entreprises.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            3. Inscription et compte
          </Typography>
          <Typography paragraph>
            Pour utiliser nos services, vous devez créer un compte en fournissant des informations exactes et à jour. Vous êtes responsable du maintien de la confidentialité de vos identifiants de connexion.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            4. Utilisation du service
          </Typography>
          <Typography paragraph>
            Vous acceptez d&apos;utiliser le service conformément à toutes les lois applicables et de ne pas :
          </Typography>
          <ul>
            <li>
              <Typography>
                Publier du contenu illégal, frauduleux ou trompeur
              </Typography>
            </li>
            <li>
              <Typography>
                Usurper l&apos;identité d&apos;une autre personne ou entreprise
              </Typography>
            </li>
            <li>
              <Typography>
                Utiliser le service pour envoyer du spam ou des communications non sollicitées
              </Typography>
            </li>
          </ul>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            5. Contenu et propriété intellectuelle
          </Typography>
          <Typography paragraph>
            Vous conservez tous les droits sur le contenu que vous publiez sur EntrePixel. En publiant du contenu, vous nous accordez une licence mondiale, non exclusive et libre de redevance pour utiliser ce contenu.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            6. Abonnements et paiements
          </Typography>
          <Typography paragraph>
            Les conditions spécifiques relatives aux abonnements, y compris les prix, la durée et les modalités de paiement, sont définies lors de la souscription. Les paiements sont traités de manière sécurisée via notre prestataire de paiement.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            7. Résiliation
          </Typography>
          <Typography paragraph>
            Nous nous réservons le droit de suspendre ou de résilier votre compte en cas de violation des présentes conditions. Vous pouvez également résilier votre compte à tout moment.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            8. Modifications des conditions
          </Typography>
          <Typography paragraph>
            Nous nous réservons le droit de modifier ces conditions à tout moment. Les modifications entrent en vigueur dès leur publication sur la plateforme. Votre utilisation continue du service après ces modifications constitue votre acceptation des nouvelles conditions.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            9. Limitation de responsabilité
          </Typography>
          <Typography paragraph>
            EntrePixel ne peut être tenu responsable des dommages directs ou indirects résultant de l&apos;utilisation ou de l&apos;impossibilité d&apos;utiliser nos services.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
            10. Contact
          </Typography>
          <Typography paragraph>
            Pour toute question concernant ces conditions d&apos;utilisation, veuillez nous contacter à l&apos;adresse : support@entrepixel.com
          </Typography>
        </Box>

        <Box sx={{ mt: 4, pt: 2, borderTop: 1, borderColor: 'divider' }}>
          <Typography variant="body2" color="text.secondary">
            Dernière mise à jour : {new Date().toLocaleDateString()}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Terms;
