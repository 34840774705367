import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  MenuItem,
  Slider,
  Typography,
  Paper,
  Chip,
  IconButton,
  Collapse,
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';

const sectors = [
  'Tous les secteurs',
  'Commerce',
  'Services',
  'Industrie',
  'Technologies',
  'Restauration',
  'Santé',
  'Education',
  'Construction',
  'Transport',
];

const regions = [
  'Toutes les régions',
  'Île-de-France',
  'Auvergne-Rhône-Alpes',
  'Nouvelle-Aquitaine',
  'Occitanie',
  'Hauts-de-France',
  'Grand Est',
  'Provence-Alpes-Côte d\'Azur',
  'Pays de la Loire',
  'Normandie',
  'Bretagne',
  'Bourgogne-Franche-Comté',
  'Centre-Val de Loire',
  'Corse',
];

interface SearchFilters {
  query: string;
  sector: string;
  region: string;
  rating: number[];
  employeeRange: number[];
  tags: string[];
}

const AdvancedSearch = ({ onSearch }: { onSearch: (filters: SearchFilters) => void }) => {
  const [filters, setFilters] = useState<SearchFilters>({
    query: '',
    sector: 'Tous les secteurs',
    region: 'Toutes les régions',
    rating: [0, 5],
    employeeRange: [0, 1000],
    tags: [],
  });

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [newTag, setNewTag] = useState('');

  const handleFilterChange = (field: keyof SearchFilters, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddTag = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && newTag.trim()) {
      setFilters((prev) => ({
        ...prev,
        tags: [...prev.tags, newTag.trim()],
      }));
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setFilters((prev) => ({
      ...prev,
      tags: prev.tags.filter((tag) => tag !== tagToRemove),
    }));
  };

  const handleSearch = () => {
    onSearch(filters);
  };

  const handleReset = () => {
    setFilters({
      query: '',
      sector: 'Tous les secteurs',
      region: 'Toutes les régions',
      rating: [0, 5],
      employeeRange: [0, 1000],
      tags: [],
    });
  };

  return (
    <Paper
      component={motion.div}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      sx={{ p: 3, mb: 4 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Rechercher une entreprise"
            value={filters.query}
            onChange={(e) => handleFilterChange('query', e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            label="Secteur d'activité"
            value={filters.sector}
            onChange={(e) => handleFilterChange('sector', e.target.value)}
          >
            {sectors.map((sector) => (
              <MenuItem key={sector} value={sector}>
                {sector}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            label="Région"
            value={filters.region}
            onChange={(e) => handleFilterChange('region', e.target.value)}
          >
            {regions.map((region) => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <Button
            startIcon={<FilterListIcon />}
            onClick={() => setShowAdvanced(!showAdvanced)}
            sx={{ mb: 2 }}
          >
            {showAdvanced ? 'Masquer les filtres avancés' : 'Afficher les filtres avancés'}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <AnimatePresence>
            {showAdvanced && (
              <Collapse in={showAdvanced}>
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Typography gutterBottom>Note moyenne</Typography>
                      <Slider
                        value={filters.rating}
                        onChange={(_, value) => handleFilterChange('rating', value)}
                        valueLabelDisplay="auto"
                        min={0}
                        max={5}
                        step={0.5}
                        marks
                        sx={{ mt: 2 }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography gutterBottom>Nombre d'employés</Typography>
                      <Slider
                        value={filters.employeeRange}
                        onChange={(_, value) => handleFilterChange('employeeRange', value)}
                        valueLabelDisplay="auto"
                        min={0}
                        max={1000}
                        step={50}
                        sx={{ mt: 2 }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Ajouter des tags (appuyez sur Entrée)"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        onKeyPress={handleAddTag}
                      />
                      <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {filters.tags.map((tag) => (
                          <Chip
                            key={tag}
                            label={tag}
                            onDelete={() => handleRemoveTag(tag)}
                            color="primary"
                            variant="outlined"
                          />
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </motion.div>
              </Collapse>
            )}
          </AnimatePresence>
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            startIcon={<ClearIcon />}
            onClick={handleReset}
          >
            Réinitialiser
          </Button>
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={handleSearch}
          >
            Rechercher
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AdvancedSearch;
