import React, { useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import AdvancedSearch from '../components/AdvancedSearch';
import SearchResults from '../components/SearchResults';

interface Company {
  id: string;
  name: string;
  description: string;
  sector: string;
  region: string;
  rating: number;
  employeeCount: number;
  logo?: string;
  tags: string[];
}

const Search = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [results, setResults] = useState<Company[]>([]);

  const handleSearch = async (filters: any) => {
    setLoading(true);
    setError(undefined);

    try {
      // TODO: Remplacer par un vrai appel API
      await new Promise(resolve => setTimeout(resolve, 1500)); // Simulation de délai
      
      // Données de test
      setResults([
        {
          id: '1',
          name: 'TechInnovate Solutions',
          description: 'Entreprise leader dans le développement de solutions technologiques innovantes pour les PME.',
          sector: 'Technologies',
          region: 'Île-de-France',
          rating: 4.5,
          employeeCount: 150,
          tags: ['Innovation', 'Tech', 'Digital', 'Cloud'],
        },
        {
          id: '2',
          name: 'GreenEco Consulting',
          description: 'Cabinet de conseil spécialisé dans la transition écologique et le développement durable.',
          sector: 'Services',
          region: 'Auvergne-Rhône-Alpes',
          rating: 4.8,
          employeeCount: 45,
          tags: ['Environnement', 'Conseil', 'RSE'],
        },
        {
          id: '3',
          name: 'BioSanté Plus',
          description: 'Laboratoire de recherche et développement dans le domaine de la santé et du bien-être.',
          sector: 'Santé',
          region: 'Occitanie',
          rating: 4.2,
          employeeCount: 280,
          tags: ['Santé', 'Recherche', 'Innovation'],
        },
      ]);
    } catch (err) {
      setError('Une erreur est survenue lors de la recherche. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Rechercher une entreprise
      </Typography>
      
      <Box sx={{ mb: 4 }}>
        <AdvancedSearch onSearch={handleSearch} />
      </Box>

      <SearchResults
        results={results}
        loading={loading}
        error={error}
      />
    </Container>
  );
};

export default Search;
