import React, { useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Alert,
  CircularProgress,
} from '@mui/material';
import { register, RegisterData } from '../services/authService';

const validationSchema = Yup.object({
  companyName: Yup.string()
    .min(2, 'Le nom doit contenir au moins 2 caractères')
    .required('Le nom de l\'entreprise est requis'),
  address: Yup.string()
    .required('L\'adresse est requise'),
  sector: Yup.array()
    .min(1, 'Sélectionnez au moins un secteur')
    .max(3, 'Maximum 3 secteurs'),
  description: Yup.string()
    .min(50, 'La description doit contenir au moins 50 caractères')
    .max(500, 'La description ne doit pas dépasser 500 caractères')
    .required('La description est requise'),
  contactName: Yup.string()
    .required('Le nom du responsable est requis'),
  email: Yup.string()
    .email('Email invalide')
    .required('L\'email est requis'),
  phone: Yup.string()
    .matches(/^[0-9+\-\s()]*$/, 'Numéro de téléphone invalide')
    .required('Le téléphone est requis'),
  website: Yup.string()
    .url('URL invalide'),
  plan: Yup.string()
    .required('Choisissez un forfait'),
  duration: Yup.string()
    .required('Choisissez une durée'),
});

const sectors = [
  'Commerce',
  'Services',
  'Industrie',
  'Technologies',
  'Restauration',
  'Santé',
  'Education',
  'Construction',
  'Transport',
];

const plans = {
  basic: {
    name: 'Basic',
    durations: ['3 mois']
  },
  premium: {
    name: 'Premium',
    durations: ['6 mois']
  },
  elite: {
    name: 'Elite',
    durations: ['1 an']
  }
};

const PlanDurationSync = () => {
  const { values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    if (values.plan && plans[values.plan as keyof typeof plans]) {
      const availableDurations = plans[values.plan as keyof typeof plans].durations;
      if (!availableDurations.includes(values.duration)) {
        setFieldValue('duration', availableDurations[0], false);
      }
    }
  }, [values.plan, values.duration, setFieldValue]);

  return null;
};

const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedPlan = location.state?.selectedPlan || '';
  const [activeStep, setActiveStep] = React.useState(selectedPlan ? 2 : 0);
  const [error, setError] = React.useState<string | null>(null);

  const steps = ['Informations entreprise', 'Contact', 'Forfait'];

  const initialValues = {
    companyName: '',
    address: '',
    sector: [],
    description: '',
    contactName: '',
    email: '',
    phone: '',
    website: '',
    socialMedia: {
      facebook: '',
      linkedin: '',
      twitter: '',
    },
    plan: selectedPlan,
    duration: selectedPlan && plans[selectedPlan as keyof typeof plans] ? plans[selectedPlan as keyof typeof plans].durations[0] : '',
  };

  const handleSubmit = async (values: RegisterData, { setSubmitting, setStatus }: any) => {
    try {
      setError(null);
      await register(values);
      setStatus({ success: true });
      // Rediriger vers la page de paiement après un court délai
      setTimeout(() => {
        navigate('/payment', { 
          state: { 
            plan: values.plan,
            duration: values.duration,
            email: values.email
          }
        });
      }, 1500);
    } catch (err: any) {
      setError(err.message);
      setStatus({ success: false });
    } finally {
      setSubmitting(false);
    }
  };

  const handleNext = (formikProps: any) => {
    const { values, validateForm } = formikProps;
    
    validateForm().then((errors: any) => {
      // Vérifier les champs requis pour chaque étape
      let isStepValid = true;
      
      if (activeStep === 0) {
        isStepValid = !errors.companyName && !errors.address && !errors.sector && !errors.description;
      } else if (activeStep === 1) {
        isStepValid = !errors.contactName && !errors.email && !errors.phone;
      } else if (activeStep === 2) {
        isStepValid = !errors.plan && !errors.duration;
      }

      if (isStepValid) {
        setActiveStep((prevStep) => prevStep + 1);
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const getStepContent = (step: number, formikProps: any) => {
    const { values, errors, touched, handleChange, handleBlur } = formikProps;

    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="companyName"
                label="Nom de l'entreprise"
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.companyName && Boolean(errors.companyName)}
                helperText={touched.companyName && errors.companyName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="address"
                label="Adresse"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                SelectProps={{ multiple: true }}
                name="sector"
                label="Secteurs d'activité"
                value={values.sector}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.sector && Boolean(errors.sector)}
                helperText={touched.sector && errors.sector}
              >
                {sectors.map((sector) => (
                  <MenuItem key={sector} value={sector}>
                    {sector}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                name="description"
                label="Description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="contactName"
                label="Nom du responsable"
                value={values.contactName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.contactName && Boolean(errors.contactName)}
                helperText={touched.contactName && errors.contactName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="email"
                label="Email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="phone"
                label="Téléphone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="website"
                label="Site web"
                value={values.website}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.website && Boolean(errors.website)}
                helperText={touched.website && errors.website}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                name="plan"
                label="Type de forfait"
                value={values.plan}
                onChange={(e) => {
                  handleChange(e);
                  if (plans[e.target.value as keyof typeof plans]) {
                    formikProps.setFieldValue('duration', plans[e.target.value as keyof typeof plans].durations[0]);
                  }
                }}
                onBlur={handleBlur}
                error={touched.plan && Boolean(errors.plan)}
                helperText={touched.plan && errors.plan}
              >
                {Object.entries(plans).map(([key, plan]) => (
                  <MenuItem key={key} value={key}>
                    {plan.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                name="duration"
                label="Durée"
                value={values.duration}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.duration && Boolean(errors.duration)}
                helperText={touched.duration && errors.duration}
                disabled={!values.plan}
              >
                {values.plan && plans[values.plan as keyof typeof plans]?.durations.map((duration) => (
                  <MenuItem key={duration} value={duration}>
                    {duration}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Inscription
        </Typography>
        
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <PlanDurationSync />
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              {formikProps.status?.success && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  Inscription réussie ! Redirection vers le paiement...
                </Alert>
              )}

              {getStepContent(activeStep, formikProps)}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mr: 1 }}>
                    Précédent
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={formikProps.isSubmitting}
                  >
                    {formikProps.isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Finaliser l\'inscription'
                    )}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleNext(formikProps)}
                    disabled={formikProps.isSubmitting}
                  >
                    Suivant
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default Register;
